<template>
  <div id="accounts">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="บัญชีแอดมิน" />
            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>ชื่อบัญชีแอดมิน</th>
                  <th>สถานะบัญชีแอดมิน</th>
                  <th v-if="admin_mmtoyshop.role === 3">เลเวล</th>
                  <th>จัดการบัญชีแอดมิน</th>
                </tr>
                <tr v-if="admins.length === 0">
                  <td colspan="5">
                    <template>
                      <div
                        style="font-size: 20px"
                        class="d-flex flex-column align-center red--text my-4"
                      >
                        <v-img
                          class="mt-2 mb-4"
                          width="200"
                          src="@/assets/no-data.svg"
                          alt=""
                        >
                        </v-img>
                        ไม่พบข้อมูลบัญชีแอดมิน
                      </div>
                    </template>
                  </td>
                </tr>
                <tr
                  class="table-body"
                  v-for="(data, index) in admins"
                  :key="index"
                >
                  <td>{{ data.username }}</td>
                  <td>
                    {{
                      data.status === 1
                        ? 'ยังไม่ได้รับการอนุมัติให้ใช้งาน'
                        : 'อนุมัติแล้ว'
                    }}
                  </td>
                  <td v-if="admin_mmtoyshop.role === 3">
                    <v-select
                      style="width: 100px"
                      :items="roleItems"
                      outlined
                      dense
                      hide-details
                      v-model="data.role"
                      @change="changeRole(data.id, data.role)"
                    ></v-select>
                  </td>
                  <td>
                    <v-btn
                      @click.prevent="setStatusAdmin(data.id, 2)"
                      small
                      color="blue"
                      class="mr-3 white--text"
                      v-if="data.status === 1"
                      >อนุมัติ</v-btn
                    >
                    <v-btn
                      @click.prevent="setStatusAdmin(data.id, 0)"
                      small
                      color="red"
                      v-if="
                        admin_mmtoyshop.name !== data.username &&
                          data.role === 1
                      "
                      class="white--text"
                      >ปฏิเสธ</v-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterAdmin from '@/components/FooterAdmin';
import HeaderPage from '@/components/HeaderPage';

import AdminService from '@/services/Admin.service';

import Swal from 'sweetalert2';

export default {
  name: 'Accounts',
  components: {
    Navigation,
    FooterAdmin,
    HeaderPage,
  },
  data() {
    return {
      admins: [],
      roleItems: [1, 2, 3],
      admin_mmtoyshop: JSON.parse(
        localStorage.getItem(process.env.VUE_APP_AUTH)
      ),
    };
  },
  created() {
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      try {
        const response = await AdminService.findAll();
        this.admins = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async setStatusAdmin(adminId, status) {
      try {
        const response = await AdminService.update(adminId, status);
        this.getAdmins();
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async changeRole(id, role) {
      const body = {
        id,
        role,
      };
      try {
        const response = await AdminService.updateRole(body);
        this.getAdmins();
        Swal.fire({
          position: 'bottom-end',
          toast: true,
          icon: 'success',
          title: response.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
