import axios from 'axios';
import authHeader from './auth-header';
class Admin {
  findAll() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get('/management/admins', {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  update(adminId, status) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.patch(
          `/management/admin?adminId=${adminId}&status=${status}`,
          null,
          {
            headers: authHeader(),
          }
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  async updateRole(body) {
    try {
      const { data } = await axios.patch('/management/admin-role', body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new Admin();
